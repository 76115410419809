@font-face {
  font-family: "Nunito Sans";
  src: url("../public/fonts/NunitoSans/NunitoSans-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Nunito Sans";
  src: url("../public/fonts/NunitoSans/NunitoSans-SemiBold.ttf");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "Nunito Sans";
  src: url("../public/fonts/NunitoSans/NunitoSans-Bold.ttf");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "Nunito Sans";
  src: url("../public/fonts/NunitoSans/NunitoSans-ExtraBold.ttf");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

html,
body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

body {
  overflow: auto !important;
  padding-right: 0 !important;
}

.Toastify__toast--success {
  /* background: #43a047; */
  /* color: white; */
}
.Toastify__close-button {
  /* color: white; */
}

.Toastify__progress-bar--success {
  background: transparent;
}

.trasition g,
.trasition path,
.trasition svg {
  transition: 100ms cubic-bezier(0.15, 0.71, 0.87, 0.65);
}
svg.active {
  animation: sizeUp 0.6s;
}
svg.delete {
  animation: sizeDown 0.6s;
}

@keyframes sizeUp {
  from {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  to {
    transform: scale(1);
  }
}
@keyframes sizeDown {
  from {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  to {
    transform: scale(1);
  }
}

.iconSVG:hover {
  color: black;
}

.gradientAnimation {
  background: linear-gradient(90deg, #ffffff, #f6f7f8, #ffffff);
  background-size: 400% 100%;
  animation: gradient 2s ease infinite;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.rowHover {
  transition: 0.1s;
}

.rowHover:hover {
  background-color: #eeeeee;
}

.nextImage span {
  border-radius: 5px;
  filter: brightness(60%);
}

@media (max-width: 600px) {
  #ComparisonModal .MuiPaper-root {
    transform: scale(0.97);
  }
}

.opacity-0 {
  opacity: 0;
}
.opacity-100 {
  opacity: 1;
}

.lineHR {
  height: 1px;
  border: 0px;
  margin: 4px 0;
  background-color: #e0e0e0;
}
