.containerLoader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: rgb(255, 255, 255), $alpha: 0.35);
  z-index: 99999;
}
